.body {
  h2 {
    color: black;
    font-size: 2vw;
  }

  .text {
    font-size: 1.1vw;
  }
  margin-bottom: 5vw;
}
