// work-process
.work-process-item {
  margin: auto;
  text-align: center;
  border-radius: 4px;
  color: $white;
  overflow: hidden;
  margin-top: 38px;
  @include desktop {
    padding: 0 50px;
  }
  @include tablet {
    padding: 0;
  }
  @include mobile {
    width: 60%;
  }
  @include mobile-xs {
    width: 100%;
    padding: 0 20px;
  }

  .process-title {
    position: relative;
    &::after {
      position: absolute;
      content: "0";
      top: 0;
      left: 50%;
      color: $white;
      opacity: .015;
      font-size: 300px;
      font-weight: 900;
      line-height: 0;
      transform: translateX(-50%);
      pointer-events: none;
      @include tablet {
        font-size: 210px;
      }
    }
  }

  .process-text {
    -webkit-text-stroke: 1px $white;
    -webkit-text-fill-color: transparent;
    font-size: 90px;
    font-weight: 700;
    line-height: 1.3;
    @include tablet {
      font-size: 60px;
    }
  }

  &.focus .process-text,
  &:hover .process-text {
    animation: shadow-anim .5s cubic-bezier(.165,.84,.44,1) infinite;
  }

  @keyframes shadow-anim {
    0% {
      text-shadow: 4px 4px rgba($color-primary, .6), -5px 4px rgba(#f0ff18, .8);
    }
    25% {
      text-shadow: -5px 4px rgba(#2196F3, .7), 4px 4px rgba(#f0ff18, .6);
    }
    50% {
      text-shadow: 4px -4px rgba(#03EC8B, .8), -5px 4px rgba(#2196F3, .2);
    }
    75% {
      text-shadow: -4px -4px rgba(#f0ff18, .7), 4px 4px rgba(#03EC8B, .6);
    }
    100% {
      text-shadow: 4px 4px rgba($color-primary, .7), -5px 4px rgba(#ff57db, .4);
    }
  }
}