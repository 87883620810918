.body {
  margin-top: 3vw;
  .box {
    top: 0;
    left: 0;

    p {
      color: #001213;
      font-size: 1.5vw;
      width: 60%;
    }
    h3 {
      color: #001213;
      font-size: 2vw;
      width: 50%;
    }
  }
}
