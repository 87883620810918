// blogs style
.blog-card {
  position: relative;
  margin-top: 30px;
  height: calc(100% - 30px);
  padding: 30px;

  @include mobile-xs {
    padding: 20px;
  }

  .card-background {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.05;
  }

  .card-image {
    overflow: hidden;
    display: block;
    width: 100%;
    height: 230px;

    img {
      transform: scale(1.02);
      transition: 0.3s;
      transform-origin: right;
      height: 100%;
      width: 100%;
    }

    &:hover img {
      transform-origin: left;
    }
  }

  .card-text {
    font-size: 15px;
  }

  .card-tag {
    font-size: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: block;

    a {
      color: $color-muted;

      &:hover {
        color: #444444;
        text-decoration: underline;
      }
    }
  }

  .card-title {
    a {
      display: inline-block;
      color: $color-dark;
      transition: 0.2s;
      text-decoration: underline;
      text-decoration-color: transparent;
      -webkit-text-decoration-color: transparent;
      -moz-text-decoration-color: transparent;

      &:hover {
        color: #222222;
        text-decoration-color: #444444;
        -webkit-text-decoration-color: #444444;
        -moz-text-decoration-color: #444444;
      }
    }
  }

  .card-meta {
    font-size: 14px;
  }
}
