@mixin mobile-xs {
  @media(max-width:420px) {
    @content;
  }
}

@mixin mobile-md {
  @media(max-width:480px) {
    @content;
  }
}

@mixin mobile {
  @media(max-width:575px) {
    @content;
  }
}

@mixin tablet {
  @media(max-width:767px) {
    @content;
  }
}

@mixin desktop {
  @media(max-width:991px) {
    @content;
  }
}

@mixin desktop-lg {
  @media(max-width:1200px) {
    @content;
  }
}

@mixin size($size) {
  width: $size;
  height: $size;
}
