.body {
  margin-top: 3vw;
  .box {
    top: 0;
    left: 0;
    .innerBox {
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(15px);
      padding: 2vw 4vw;

      p {
        color: #ffffff;
        font-size: 1.5vw;
      }
      h3 {
        color: #ffffff;
        font-size: 2vw;
      }
    }
  }
}
