.body {
  margin-top: 4vw;
  background-color: #001213;

  padding-top: 2vw;
  padding-bottom: 2vw;

  h3 {
    color: #ffffff;
    font-size: 2.5vw;
  }

  p {
    color: #ffffff;
    font-size: 1.5vw;
  }
}
