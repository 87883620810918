// banner style
.banner {
  padding-top: 130px;
  padding-bottom: 150px;
  color: #aaa;
  @include desktop {
    padding: 90px 0;
  }
  @include tablet {
    padding-top: 60px;
  }
  .banner-title {
    font-size: 56px;
    font-weight: 300;
    line-height: 1.2;
    @include desktop {
      font-size: 50px;
    }
    @include tablet {
      font-size: 44px;
    }
    @include mobile {
      font-size: 38px;
    }
    @include mobile-md {
      font-size: 36px;
    }
  }
  
  .banner-shape {
    height: 600px;
    width: 560px;
    max-width: 100%;
    position: absolute;
    top: -50%;
    left: -200px;
    z-index: -1;
    opacity: .2;
    color: #332D2B;
    @include tablet {
      left: -100px;
      opacity: .3;
    }
  }
  
  &.personal-portfolio {
    padding: 100px 0 40px 0;
    @include tablet {
      padding: 60px 0 0 0;
    }
    .user-image {
      position: relative;
      padding: 20px;
      max-width: 100%;
      @include desktop {
        padding: 15px;
        margin-bottom: 45px;
        width: 360px;
      }
      @include tablet {
        width: 320px;
        padding: 10px;
      }
      @include mobile {
        width: 280px;
      }
      &::before,
      &::after {
        position: absolute;
        content: "";
        height: calc(100% - 20px);
        width: 50%;
        background-color: $color-primary;
        right: 0;
        top: -20px;
        z-index: -2;
        @include desktop {
          top: -15px;
        }
        @include desktop {
          top: -10px;
        }
      }
      &::before {
        top: auto;
        bottom: -20px;
        right: auto;
        left: 0;
        @include desktop {
          bottom: -15px;
        }
        @include tablet {
          bottom: -10px;
        }
      }
      .shape {
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        &::before {
          content: "\f6bb";
          font-size: 48vw;
          color: $color-dark;
          line-height: 1;
          font-weight: normal;
          font-family: 'Line Awesome Free';
          font-weight: 900;
          z-index: -1;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          @include desktop {
            font-size: 62vw;
          }
          @include tablet {
            font-size: 85vh;
          }
          @include mobile {
            font-size: 110vh;
          }
        }
      }
    }
  }
}

// page-header
.page-header {
  padding: 50px 0 60px 0;
  color: $color-light;
}