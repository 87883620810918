.body {
  margin-top: 3vw;
  .box {
    width: 43%;
    height: 60%;
    right: 0%;
    bottom: 0;
    p {
      color: #fb8034;
      font-size: 1.5vw;
    }
    h3 {
      color: #001213;
      font-size: 2vw;
    }

    .icon {
      right: 0;
      top: 41%;
      width: 50%;
      cursor: pointer;
    }
  }
}
