// Colors
$color-primary: #00b75f;
$color-light: #bbb;
$color-dark: #001213;
$color-muted: #909090;
$black: #000;
$white: #fff;

// Fonts
$font-primary: 'Space Grotesk', sans-serif;

// Typography
$h1: 48px;
$h1-md: 40px;
$h2: 38px;
$h2-md: 30px;
$h3: 30px;
$h3-md: 26px;
$h4: 24px;
$h5: 18px;
$h6: 15px;
$body-text-size : 16px;