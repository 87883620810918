// portfolio style
.portfolio-card {
  border: 0;
  border-radius: 0;
  overflow: hidden;
  background-color: transparent;
  .card-img {
    border-radius: 0 !important;
  }
}

.card-title,
.card-subtitle,
.card-img-overlay,
.card-img img {
  display: block;
  will-change: transform, opacity;
  transition: transform .7s cubic-bezier(.2,1,.2,1),opacity .7s cubic-bezier(.2,1,.2,1),-webkit-transform .7s cubic-bezier(.2,1,.2,1);
}

.card-title a,
.card-subtitle a {
  color: $white;
  text-decoration: underline;
  text-decoration-color: transparent;
  -webkit-text-decoration-color: transparent;
  -moz-text-decoration-color: transparent;
  &:hover {
    text-decoration: underline !important;
    text-decoration-color: $white;
    -webkit-text-decoration-color: $white;
    -moz-text-decoration-color: $white;
  }
}

.card-overlay {
  .card-img-overlay {
    top: auto;
    border-radius: 0;
    z-index: 3;
    padding: 3rem;
    @include mobile {
      padding: 2rem;
    }
  }
  .card-title {
    transform: translateY(100%);
    @include desktop {
      font-size: 30px;
    }
  }
  .card-subtitle {
    font-size: 15px;
    opacity: .8;
    transform: translateY(calc(-100% - 15px));
  }
  &:hover {
    .card-img-overlay,
    .card-title,
    .card-subtitle {
      transform: translateY(0);
    }
  }
}

.card-img {
  .img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    opacity: 0;
    background-color: $black;
    transition: .3s ease;
  }
}

.card-hover-zoom:hover {
  .card-img {
    img {
      transform: scale(1.2);
    }
    .img-overlay {
      opacity: 0.95;
    }
  }
}

// portfolio-carousel-fluid
.portfolio-carousel-fluid {
  .swiper-container {
    cursor: grab;
  }
  .swiper-slide .portfolio-card {
    margin: 0 40px;
    @include desktop-lg {
      margin: 0 30px;
    }
    @include desktop {
      margin: 0 15px;
    }
  }
  .card-img-overlay {
    height: 100%;
    top: 0;
    padding: 1.25rem;
    transform: translateY(0);
    display: flex;
    align-items: center;
    justify-content: center;
    .block {
      .card-title,
      .card-subtitle {
        opacity: 0;
        transition: transform .3s cubic-bezier(.2,1,.2,1),opacity .3s cubic-bezier(.2,1,.2,1),-webkit-transform .3s cubic-bezier(.2,1,.2,1) !important;
        transform: translateY(60px);
      }
      .card-subtitle {
        transition-delay: 0s !important;
      }
    }
  }
  .card-hover-zoom:hover {
    .card-img-overlay .block {
      .card-title,
      .card-subtitle {
        opacity: 1;
        transform: translateY(0);
      }
      .card-subtitle {
        transition-delay: .2s !important;
      }
    }
  }
  .swiper-pagination-progressbar {
    top: auto;
    bottom: 10px;
    left: calc(50% - 165px);
    width: 330px;
    height: 3px;
    background: rgba(200, 200, 200, 0.4);
    transition-timing-function: cubic-bezier(.165,.84,.44,1);

    @include mobile {
      left: calc(50% - 100px);
      width: 200px;
    }

    .swiper-pagination-progressbar-fill {
      background: $color-dark;
    }
  }
  .swipeCarousel-control {
    position: relative;
    height: 50px;
    top: -10px;
    pointer-events: none;
    .activeslide, .totalslide {
      color: $black;
      position: absolute;
      top: calc(50% + 5px);
      left: calc(50% - 200px);
      line-height: 1;
      font-weight: 600;

      @include mobile {
        left: calc(50% - 140px);
      }
    }
    .totalslide {
      left: auto;
      right: calc(50% - 200px);

      @include mobile {
        right: calc(50% - 140px);
      }
    }
  }
  .swiper-button {
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 700;
    color: $black;
    padding: 10px 12px;
  }
}


// portfolio-masonry
.portfolio-masonry {
  .row {
    @include desktop {
      margin-bottom: 30px;
    }
  }
  .portfolio-item {
    margin-bottom: 80px;
    @include desktop {
      margin-bottom: 30px;
    }
  }
}


// portfolio-grid-creative
.portfolio-grid-creative {
  .portfolio-card {
    .block {
      background-color: $white;
      position: relative;
      z-index: 2;
      margin: 0 30px;
      margin-top: -40px;
      padding: 15px 25px;
      transition: margin .3s;
      text-align: center;
    }
    &:hover .block {
      margin-top: -50px;
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}


// portfolio-floating
.portfolio-floating {
  .portfolio-card {
    overflow: initial;
    position: initial;
  }
}
.floating-info-block-tile {
  position: fixed;
  z-index: 6;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  padding: 18px 20px 10px 20px;
  color: $black;
  pointer-events: none;
  > * {
    color: $black !important;
  }
  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    transform: scale(0.4);
    background-color: $white;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  &.visible {
    opacity: 1;
    visibility: visible;
    &::after {
      transform: scale(1);
      transition: .5s cubic-bezier(.2,1,.2,1);
    }
  }
}
.floating-image-block {
  position: relative;
  &::before {
    content: attr(data-text);
    font-weight: 700;
    margin-right: 15px;
    position: absolute;
    bottom: 27px;
    left: 30px;
    transition: 0.3s cubic-bezier(.2,1,.2,1);
    transition-delay: .2s;
    opacity: 0;
  }
  &:hover::before {
    left: 0;
    opacity: 1;
  }
  .block {
    position: relative;
    z-index: 1;
    display: inline-block;
    .floating-image-link {
      display: inline-block;
      z-index: 1;
      line-height: 1;
      a {
        padding: 20px 0;
        display: block;
        font-size: 62px;
        @include tablet {
          font-size: 52px;
        }
        @include mobile {
          font-size: 42px;
        }
      }
      &:hover a {
        padding-left: 30px;
        color: $white !important;
      }
    }
    .floating-image {
      width: 380px;
      @include desktop {
        width: 280px;
      }
      position: fixed;
      z-index: -1;
      top: 0;
      left: 0;
      pointer-events: none;
      .image {
        overflow: hidden;
        img {
          max-width: 100%;
          transform: scale(1.2);
          opacity: 0;
          transition: transform 1s cubic-bezier(.2,1,.2,1), opacity .1s ease-out;
        }
      }
    }
    &.enter {
      z-index: 5;
      .image {
        img {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
  }
}


// broken-portfolio
.broken-portfolio {
  .card-img-overlay {
    height: 100%;
    top: 0;
    padding: 1.25rem;
    transform: translateY(0);
    display: flex;
    align-items: center;
    justify-content: center;
    .block {
      .card-title,
      .card-subtitle {
        opacity: 0;
        transition: transform .3s cubic-bezier(.2,1,.2,1),opacity .3s cubic-bezier(.2,1,.2,1),-webkit-transform .3s cubic-bezier(.2,1,.2,1) !important;
        transform: translateY(60px);
      }
      .card-subtitle {
        transition-delay: 0s !important;
      }
    }
  }
  .card-hover-zoom:hover {
    .card-img-overlay .block {
      .card-title,
      .card-subtitle {
        opacity: 1;
        transform: translateY(0);
      }
      .card-subtitle {
        transition-delay: .2s !important;
      }
    }
  }
}