.team-group-carousel {
  .swiper-container {
    cursor: grab;
    @include tablet {
      padding-right: 100px;
    }
    @include mobile {
      padding-right: 75px;
    }
    @include mobile-md {
      padding-right: 50px;
    }
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
  }

  .team-group-image {
    text-align: center;
    overflow: hidden;
    display: inline-block;

    img {
      max-width: 100%;
      transition: 2s cubic-bezier(.165, .84, .44, 1);

      &.hold {
        transform: scale(1.2);
        transition: 0.3s cubic-bezier(.165, .84, .44, 1);
      }
    }
  }
}


// team section
.row-team {
  @include mobile-xs {
    .col-6 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
.team-item {
  margin-top: 35px;

  .block {
    img {
      transition: 0.3s;
    }
    .social-profile {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(rgba(0, 0, 0, 0) 0%, $black 100%);
      z-index: 2;
      opacity: 0;
      transition: 0.3s;
      ul {
        @include mobile-md {
          text-align: center;
        }
        li {
          transform: translateY(-30px);
          opacity: 0;
          transition: transform 0.3s, opacity 0.2s;
          &:nth-child(2) {transition-delay: .08s}
          &:nth-child(3) {transition-delay: .16s}
          &:nth-child(4) {transition-delay: .24s}
          &:nth-child(5) {transition-delay: .32s}
          &:nth-child(6) {transition-delay: .4s}
          @include mobile-md {
            margin: 0 0.5rem;
          }
          a {
            font-size: 22px;
            color: $white;
            &:hover {
              opacity: .7;
            }
          }
        }
      }
    }
  }
  &:hover .block {
    img {
      transform: scale(1.1);
    }
    .social-profile {
      opacity: 1;
      ul li {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  @include mobile {
    width: 100%;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }
}


// open job positions
.open-positions-list {
  li {
    &:not(:last-child) {
      padding-bottom: 25px;
      margin-bottom: 19px;
      border-bottom: 1px solid #20222d;
      @include tablet {
        padding-bottom: 20px;
        margin-bottom: 14px;
      }
    }
  }
}


// about me style
.about-me-image {
  border-top-left-radius: 300px;
  border-top-right-radius: 300px;
  box-shadow: 0 -5px 0 $color-primary, 0 -10px 0 rgba($color-primary,.5), 0 -15px 0 rgba($color-primary,.25);
}
.about-me-slider {
  position: sticky;
  top: 50px;
}
.about-me-block {
  &:not(:last-child) {
    margin-bottom: 80px;
  }
  .about-me-title {
    position: relative;
    padding-left: 30px;
    margin-bottom: 40px;
    &::before {
      position: absolute;
      content: "";
      height: 100%;
      width: 12px;
      background-color: $color-primary;
      top: 0;
      left: 0;
      border-radius: 15px;
    }
  }
}